import { useEffect, useContext } from "react";
import { ThirdPartyScriptsContext } from "./ThirdPartyScriptsContext";
import { CookiebotConsent } from "@ploy-lib/snippet-messaging-types";

// Declare Cookiebot global property on window
declare global {
	interface Window {
		Cookiebot: any;
	}
}

export function CookieBotHandleOnConsentReady({ dispatch }: { dispatch: any }) {
	const { settings: { cookieBotId } } = useContext(ThirdPartyScriptsContext);
	return cookieBotId ? (<CookieBotHandleOnConsentReadyInternal dispatch={dispatch} />) : null;
}

function CookieBotHandleOnConsentReadyInternal({ dispatch }: { dispatch: any  }) {
	useEffect(() => {
		/*
		 * Cookiebot event CookiebotOnConsentReady:
		 *   The event is triggered when the user’s consent state is ready, either from being submitted or loaded from an existing cookie. 
		 *   Listen for this event if you need to retreive the user’s consent and run additional scripts as soon as possible based on consent values.
		 * https://www.cookiebot.com/en/developer/#:~:text=CookiebotOnConsentReady
		 */
		window.addEventListener("CookiebotOnConsentReady",
			() => {
				/* Will trigger a recalculation of form - running through all DR CalcRules. */
				dispatch({
					type: "patch",
					payload: {
						patches: [
							{
								target: "CookiebotOnConsentReady",
								namespace: "Main",
								/*
								 * Math.random() to generate a random value to guarantee a recalculate happens 
								 * (recalculation only happens if there is a change).
								 * Some math magic to also guarantee number is > 0 and not a decimal number.
								 */
								value: window?.Cookiebot?.consent?.statistics ? ((Math.random() + 1) * 100 | 0) : 0,
								overwrite: true,
								changeTrigger: "CookiebotOnConsentReady",
								isResolve: true
							}
						]
					}
				 });
			});

		if (inIframe()) {
			/*
			 * Message event when in an iframe. Cookiebot consent must be
			 * posted from the outside into the iframe.
			 */
			window.addEventListener("message",
				(event: MessageEvent) => {
					try {
						const consent = JSON.parse(event.data) as CookiebotConsent;
						if (consent.type === "cookiebotConsent") {
							dispatch({
								type: "patch",
								payload: {
									patches: [
										{
											target: "GoogleAnalyticsClientID",
											namespace: "Main",
											value: consent.consentId,
											overwrite: true,
											changeTrigger: "GoogleAnalyticsClientID",
											isResolve: true
										}
									]
								}
							});
						}
					}
					catch { }
				});
		}
	}, [dispatch]);
	return null;
}

export function CookieBotPreGtm() {
	useEffect(() => {
		CookieBotBeforeGtag();
	}, []);

	return null;
}

const CookieBotBeforeGtag = () => {
	window["dataLayer"] = window["dataLayer"] || [];

	function gtag(...args: any[]) {
		window["dataLayer"].push(args);
	}

	gtag("consent", "default", {
		ad_storage: "denied",
		analytics_storage: "denied",
		wait_for_update: 500
	});

	gtag("set", "ads_data_redaction", true);
};

export const CookieBotPostGtm = ({ cookiebotId }: { cookiebotId: string }) => {
	useEffect(() => {
		CookieBotPostGtag(cookiebotId);
	}, [cookiebotId]);

	return null;
};

const CookieBotPostGtag = (cookieBotId: any) => {
	if (!cookieBotId) return;
	const firstScriptTag = window.self.document.getElementsByTagName("script")[0];

	const existingScriptTag = window.self.document.getElementById("Cookiebot");
	if (existingScriptTag != null) return;

	const scriptTag = document.createElement("script");
	scriptTag.id = "Cookiebot";
	scriptTag.type = "text/javascript";
	scriptTag.src = "https://consent.cookiebot.com/uc.js";
	scriptTag.setAttribute("data-cbid", cookieBotId);
	scriptTag.setAttribute("data-blockingmode", "auto");

	firstScriptTag &&
		firstScriptTag.parentNode &&
		firstScriptTag.parentNode.insertBefore(scriptTag, firstScriptTag);
};

function inIframe() {
	try {
		return window.self !== window.top;
	} catch (e: any) {
		return true;
	}
}